import React, { ReactNode } from "react"
import { graphql, Link as GatsbyLink } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Heading, Image, Box, Link, jsx } from "theme-ui"
import TopMenu from "./TopMenu"

const MdxLink: React.FC<{href: string, children: ReactNode}> = ({href, children}) =>
    <Link
        as={GatsbyLink}
        //@ts-expect-error
        to={href}
    >
        { children }
    </Link>

export default function PageTemplate({ data: { mdx } }) {
  return (
    <div sx={{m:0, p:0}}>
      <Box sx={{ height: '50vh', margin:0, overflow: 'hidden'}}>   
        <Image
            src={mdx.frontmatter.showcase.picture}
            sx={{
                width: '100%',
                height: '100%',
                maxHeight: '50vh',
                objectFit: 'cover',
                filter: 'blur(4px) brightness(70%)'
            }}
        />
        <TopMenu/>
        <Box sx={{
            position: 'absolute',
            top: '25%',
            left: '50%',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
        }}>  
            <Heading as='h1' style={{ color: 'white'}}>
                {mdx.frontmatter.title}
            </Heading>
        </Box>
      </Box>
      <Box>
        <MDXProvider components={{Link: MdxLink}}>
            <MDXRenderer frontMatter={mdx.frontmatter}>{mdx.body}</MDXRenderer>
        </MDXProvider>
      </Box>
      
    </div>
  )
}

export const pageQuery = graphql`
  query ProjectQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        showcase {
            picture
        }
      }
    }
  }
`